import BanActionBuilder from "../models/PunishActions/actions/Ban/BanActionBuilder";
import DeleteMessageActionBuilder from "../models/PunishActions/actions/DeleteMessage/DeleteMessageActionBuilder";
import DisabledActionBuilder from "../models/PunishActions/actions/Disabled/DisabledActionBuilder";
import IncrementalActionBuilder from "../models/PunishActions/actions/Incremental/IncrementalActionBuilder";
import KickActionBuilder from "../models/PunishActions/actions/Kick/KickActionBuilder";
import MuteActionBuilder from "../models/PunishActions/actions/Mute/MuteActionBuilder";
import MuteMediaActionBuilder from "../models/PunishActions/actions/MuteMedia/MuteMediaActionBuilder";
import NotifyActionBuilder from "../models/PunishActions/actions/Notify/NotifyActionBuilder";
import LogActionBuilder from "../models/PunishActions/actions/Log/LogActionBuilder";

import { keyBy } from "lodash";

export function getPunishActionBuilders() {
  return keyBy([
    BanActionBuilder,
    DeleteMessageActionBuilder,
    DisabledActionBuilder,
    IncrementalActionBuilder,
    KickActionBuilder,
    MuteActionBuilder,
    MuteMediaActionBuilder,
    NotifyActionBuilder,
    LogActionBuilder,
  ], 'type')
}
