


































































import { InputSetups } from "@/mixins/input-setups";

import SwitchInput from 'piramis-base-components/src/components/Pi/fields/SwitchInput/SwitchInput.vue';

import { Component, PropSync, Mixins, Prop } from 'vue-property-decorator';

@Component({
  components: {
    SwitchInput
  }
})
export default class FilterJournal extends Mixins(InputSetups) {
  @PropSync('banField') journalBan!: boolean

  @PropSync('warnField') journalWarn!: boolean

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  get commonConfig() {
    return this.$store.state.chatState.chat?.common_config
  }

  get journal_moderation_all_warn() {
    return this.commonConfig && this.commonConfig.journal_moderation_all_warn
  }

  get journal_moderation_all_ban() {
    return this.commonConfig && this.commonConfig.journal_moderation_all_ban
  }
}
