import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { KickAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";

class KickActionBuilder extends BaseBuilderClass {
  type = PunishActionTypesEnum.Kick

  icon = 'logout'

  view = () => import('@/components/Modules/punish-actions/KickActionView.vue');

  getModel(): KickAction {
    return plainToInstance(KickAction, {
      type: PunishActionTypesEnum.Kick,
      delete_message: true,
      delete_message_delay: 0,
      message: []
    });
  }
}

export default new KickActionBuilder()
