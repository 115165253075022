import { BasePunishActionModel, PunishActionTypesEnum } from "../models/PunishActions";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { snakeCase } from "lodash";

export abstract class BaseBuilderClass {
  abstract type: PunishActionTypesEnum

  abstract icon: string;

  abstract view?: VueComponent

  abstract getModel(): BasePunishActionModel

  actionTitleKey() {
    if (this.type) {
      return `punish_action_type_${ snakeCase(this.type) }`
    }

    return ''
  }

  actionHelpMessageKey() {
    if (this.type) {
      return `${ this.actionTitleKey() }_help_message`
    }

    return ''
  }

  previewActionTitleKey() {
    return this.actionTitleKey()
  }
}
