import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { IncrementalAction, PunishActionTypesEnum } from "../..";

class IncrementalActionBuilder extends BaseBuilderClass {
  type = PunishActionTypesEnum.Incremental

  icon = 'stacked_line_chart'

  view = () => import('@/components/Modules/punish-actions/IncrementalActionView.vue');

  getModel(): IncrementalAction {
    return new IncrementalAction()
  }
}

export default new IncrementalActionBuilder()
