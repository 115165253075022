



























































































































import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import {
  BanAction, BasePunishActionModel,
  MessagePunishAction, MuteAction, MuteMediaAction
} from "@/includes/logic/Modules/models/PunishActions";

import ConfigField from "piramis-base-components/src/components/ConfigField/ConfigField.vue";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";
import { TagData } from "piramis-base-components/src/components/Tags/types";
import { StepUnit } from "piramis-base-components/src/components/PeriodSimplifier/types";
import PeriodSimplifierMixin from "piramis-base-components/src/components/PeriodSimplifier/PeriodSimplifierMixin";

import { Component, Emit, Mixins, Prop, Watch } from 'vue-property-decorator';

@Component({
  components: {
    ConfigField,
    Icon
  },
})
export default class PunishActionPreview extends Mixins(PeriodSimplifierMixin) {
  @Prop() action!: BasePunishActionModel | null

  @Prop() builder!: BaseBuilderClass | undefined

  @Prop({ type: Boolean, default: true, required: false }) hasDisableButtonAction!: boolean

  @Prop({ type: Boolean, default: true, required: false }) hasTitle!: boolean

  @Prop() titleKey!: string

  @Prop({ type: Boolean, default: true }) bordered!: boolean

  @Prop({ type: Boolean, default: true, required: false }) hasRemoveButton!: boolean

  @Prop({ type: Boolean, required: false, default: false }) disabled!: boolean

  @Prop() tags!: Array<TagData> | null

  @Prop({ type: Boolean, default: false, required: false }) hasEditButton!: boolean

  @Emit()
  onDisableActionClick() {
    return
  }

  @Emit()
  onEditClick() {
    return this.action
  }

  @Emit()
  onRemoveClick() {
    return this.action && this.action.type
  }

  get titleTranslations() {
    const getFields = (title: string, helpMessageKey: string, miniHelpMessageKey: string) => {
      return {
        title: this.$t(title),
        miniHelpMessage: this.$te(miniHelpMessageKey) ? this.$t(miniHelpMessageKey).toString() : null,
        helpMessage: this.$te(helpMessageKey) ? this.$t(helpMessageKey).toString() : null
      }
    }

    if (this.titleKey) {
      return getFields(`field_${ this.titleKey }_title`,
                       `field_${ this.titleKey }_help_message`,
                       `field_${ this.titleKey }_mini_help_message`,
      )
    } else {
      return getFields(`field_punish_action_title`,
                       `field_punish_action_help_message`,
                       `field_punish_action_mini_help_message`)
    }
  }

  // *******************************************************************

  @Watch('action.time', { immediate: true })
  onChange(value: number | undefined) {
    if (typeof value === 'number') {
      this.calculateCurrentUnit(this.unitOptions, value, this.unitOptions[0])
    }
  }

  get unitOptions(): Array<number> {
    return [ StepUnit.Second, StepUnit.Minute, StepUnit.Hour, StepUnit.Day, StepUnit.Week, StepUnit.Month ]
      .map((unit: StepUnit) => unit / StepUnit.Second)
  }

  get unitLabelTranslation() {
    if (this.action) {
      if (this.currentUnit
        && (
          this.action instanceof BanAction
          || this.action instanceof MuteAction
          || this.action instanceof MuteMediaAction
        )
      ) {
        return this.unitLabel(this.currentUnit, this.action.time)
      }
    }

    return ''
  }
  // *******************************************************************

  get actionTitle() {
    if (this.action && this.builder) {
      if (this.currentUnit
        && (
          this.action instanceof BanAction
          || this.action instanceof MuteAction
          || this.action instanceof MuteMediaAction
        )
      ) {
        const withUnitTranslationKey = this.action.previewActionTitleKey() + "_with_unit"

        if (this.action.time > 0) {
          return this.$t(withUnitTranslationKey, [ `${ this.$t('on_value_title') } ${ this.action.time / this.currentUnit } ${ this.unitLabelTranslation }` ])
        }

        return this.$t(withUnitTranslationKey, [ this.$t('punish_time_infinity').toString().toLowerCase() ])
      }

      return this.$t(this.builder.previewActionTitleKey())
    }

    return null
  }

  get messageIfExists() {
    if (this.action) {
      if (this.action instanceof MessagePunishAction) {
        const { message } = this.action

        if (message.length) {
          return message[0].text.trim().replaceAll("\n", "</br>")
        }

        return this.$t('punish_action_message_not_set').toString()
      }

      return null
    }

    return null
  }
}
