import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { DisabledAction, PunishActionTypesEnum } from "../..";

import { VueComponent } from "piramis-base-components/src/shared/types/VueComponent";

import { plainToInstance } from "class-transformer";

class DisabledActionBuilder extends BaseBuilderClass {
  type = PunishActionTypesEnum.Disabled

  icon = 'remove_moderator'

  view?: VueComponent | undefined

  getModel(): DisabledAction {
    return plainToInstance(DisabledAction, {
      type: PunishActionTypesEnum.Disabled
    });
  }
}

export default new DisabledActionBuilder()
