import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { MuteAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";

class MuteActionBuilder extends BaseBuilderClass {
  type = PunishActionTypesEnum.Mute

  icon = 'volume_off'

  view = () => import('@/components/Modules/punish-actions/MuteActionView.vue');

  getModel(): MuteAction {
    return plainToInstance(MuteAction, {
      type: PunishActionTypesEnum.Mute,
      delete_message: true,
      delete_message_delay: 0,
      time: 600,
      message: []
    })
  }
}

export default new MuteActionBuilder()
