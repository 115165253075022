import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";
import { LogAction, PunishActionTypesEnum } from "../..";

import { plainToInstance } from "class-transformer";
import { snakeCase } from "lodash";

class LogActionBuilder extends BaseBuilderClass {
  type = PunishActionTypesEnum.Log

  icon = 'book'

  view = undefined

  getModel(): LogAction {
    return plainToInstance(LogAction, {
      type: PunishActionTypesEnum.Log,
    });
  }

  previewActionTitleKey() {
    return `punish_action_type_${ snakeCase(this.type) }`
  }
}

export default new LogActionBuilder()
